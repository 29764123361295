import { useEffect, useState } from "react";
import useScreenSize from "./use-screen-size";

function useToggleMinimizeChat() {
  const isDesktop = useScreenSize("breakpointLg");

  // const [firstTime, setFirstTime] = useState(true);

  // We need to wait for the same time from gatsby-browser.js delay
  const WAIT_TO_LOAD_FIRST_TIME = 13 * 1000;

  const [showingChat, setShowingChat] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);

  useEffect(() => {
    const isExistingSession = JSON.parse(sessionStorage.getItem("isExistingSession"));
    // console.log("isDesktop changed to:", isDesktop);
    setTimeout(
      () => {
        let iframeContainerToHide = document.getElementById("lex-web-ui-iframe");
        if (iframeContainerToHide) {
          // console.log("Session existing:", isExistingSession);
          let isHidden = iframeContainerToHide.classList.contains("d-none");

          if (!isHidden) iframeContainerToHide.classList.add("d-none");

          if (!isDesktop) {
            // console.log("Is Mobile");
            // if (!showingChat) iframeContainerToHide.style.display = "none";
            if (!showingChat) iframeContainerToHide.classList.add("d-none");
          } else {
            // console.log("Is Desktop");
            setTimeout(
              () => {
                iframeContainerToHide.classList.remove("d-none");
                // setFirstTime(false);
                // iframeContainerToHide.style.display = "flex";
              },
              isExistingSession ? 2000 : 0
            );
          }
        }
      },
      isExistingSession ? WAIT_TO_LOAD_FIRST_TIME + 500 : 0
    );
  }, [isDesktop]);

  const host =
    typeof window !== `undefined`
      ? window.location.hostname === "www.wafdbank.com"
        ? "https://chat.wafdbank.com"
        : "https://chat-dev.pikestreet.io"
      : "https://chat-dev.pikestreet.io";

  const sendMessageToIframe = (messageChannel, evt) => {
    messageChannel.port1.close();
    messageChannel.port2.close();
    /* if (evt.data.event === "resolve") {
    console.log("iframe successfully handled our message", evt.data);
  } else {
    console.error("iframe failed to handle our message", evt.data);
  } */
  };

  const postMessage = () => {
    // console.log("Post message initialized...");
    let iframeElement = document.querySelector(".lex-web-ui-iframe iframe");
    let messageChannel = new MessageChannel();
    let message = { event: "toggleMinimizeUi" };
    messageChannel.port1.onmessage = (ev) => sendMessageToIframe(messageChannel, ev);

    let iframeContainerToHide = document.getElementById("lex-web-ui-iframe");

    let isMobile = !isDesktop ? true : false;
    // console.log("Current isMobile:", isMobile);

    let doShow =
      (iframeContainerToHide.classList.contains("lex-web-ui-iframe") &&
        !iframeContainerToHide.classList.contains("lex-web-ui-iframe--minimize") &&
        !iframeContainerToHide.classList.contains("lex-web-ui-iframe--show")) ||
      iframeContainerToHide.classList.contains("lex-web-ui-iframe--minimize")
        ? true
        : false;

    if (isMobile) {
      // console.log("Is mobile version");
      if (doShow) {
        // iframeContainerToHide.style.display = "flex";
        iframeContainerToHide.classList.remove("d-none");
      } else {
        // iframeContainerToHide.style.display = "none";
        iframeContainerToHide.classList.add("d-none");
      }
    }

    setShowingChat(doShow);
    // console.log("Current doShow:", doShow);

    iframeElement.contentWindow.postMessage(message, host, [messageChannel.port2]);
    // console.log("Post message finished...");
  };

  function handleChatScriptLoad() {
    // console.log("Hook -> AWS chat 2nd script loaded");
    var loaderOpts = {
      baseUrl: host,
      shouldLoadMinDeps: true
    };
    var loader = new ChatBotUiLoader.IframeLoader(loaderOpts);
    var chatbotUiConfig = {};
    loader
      .load(chatbotUiConfig)
      .then(function () {
        // console.log("chatbot UI loaded");
        // console.log("Chat clicked check passed");
        postMessage();
        setChatLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        // console.log("Chat clicked check not passed");
        setChatLoading(false);
      });
  }

  const runChatScriptLoad = () => {
    let script = document.createElement("script");
    script.src = `${host}/lex-web-ui-loader.min.js`;
    script.defer = true;
    script.onload = handleChatScriptLoad;
    document.body.appendChild(script);
  };

  const onClickOpenChat = async (chatLoading, setChatLoading) => {
    // console.log("Starting onClickOpenChat");
    if (chatLoading) {
      // console.log("Already started, call stopped");
      return;
    }

    setChatLoading(true);

    let iframeElement = document.querySelector(".lex-web-ui-iframe iframe");
    if (!iframeElement) {
      // console.log("Chat not found, loading chat...");
      await runChatScriptLoad();
    } else {
      postMessage();
      // console.log("Chat clicked check passed");
      setChatLoading(false);
    }

    // console.log("Finished onClickOpenChat");
  };

  const toggleChat = () => {
    onClickOpenChat(chatLoading, setChatLoading);
  };

  return { toggleChat, chatLoading };
}

export default useToggleMinimizeChat;

